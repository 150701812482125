// import { PlusOutlined } from '@ant-design/icons';
// import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
// import React, { useState } from 'react';
// import HostForm from './HostForm';

// const { Option } = Select;

// const EventForm = ({
// 	userId,
// 	subscriptionId,
// 	selectedPackId,
// 	createEvent,
// 	eventCreated,
// 	creationType
// }) => {
// 	const [form] = Form.useForm();
// 	const [isGuestUploadEnabled, setIsGuestUploadEnabled] = useState(false);
// 	const [hosts, setHosts] = useState([{ name: '', email: '', mobileNumber: '' }]);

// 	const handleCreateEvent = async (values) => {
// 		const eventData = {
// 			name: values.eventName,
// 			EventType: creationType === 'subscription' ? 1 : 0,
// 			purchaseId: creationType === 'pack' ? selectedPackId : null,
// 			subscriptionId: creationType === 'subscription' ? subscriptionId : null,
// 			isGuestUploadEnabled,
// 			guestMaxPhotos: isGuestUploadEnabled ? values.guestMaxPhotos : null,
// 			category: values.category,
// 			eventDate: values.eventDate.toISOString().split('T')[0],
// 			allowEnquiry: values.allowEnquiry,
// 			hosts: hosts.filter((host) => host.name && host.email)
// 		};

// 		try {
// 			await createEvent(userId, eventData);
// 			eventCreated();
// 		} catch (error) {
// 			console.error('Failed to create event:', error);
// 		}
// 	};

// 	const addHost = () => {
// 		if (hosts.length < 5) {
// 			setHosts([...hosts, { name: '', email: '', mobileNumber: '' }]);
// 		}
// 	};

// 	const updateHost = (index, field, value) => {
// 		const updatedHosts = [...hosts];
// 		updatedHosts[index][field] = value;
// 		setHosts(updatedHosts);
// 	};

// 	const removeHost = (index) => {
// 		const updatedHosts = hosts.filter((_, i) => i !== index);
// 		setHosts(updatedHosts);
// 	};

// 	return (
// 		<Form form={form} onFinish={handleCreateEvent} layout="vertical">
// 			<Row gutter={16}>
// 				<Col xs={24} lg={24}>
// 					<Form.Item
// 						name="eventName"
// 						label="Event Name"
// 						rules={[{ required: true, message: 'Please enter event name' }]}>
// 						<Input />
// 					</Form.Item>
// 				</Col>
// 			</Row>

// 			<Row gutter={16}>
// 				<Col xs={24} lg={12}>
// 					<Form.Item
// 						name="category"
// 						label="Event Category"
// 						rules={[{ required: true, message: 'Please select event category' }]}>
// 						<Select>
// 							{[
// 								'Wedding',
// 								'Engagement',
// 								'Birthday',
// 								'Corporate',
// 								'Couple Shoot',
// 								'Family Photoshoot',
// 								'Maternity Photoshoot',
// 								'Fashion Model Photoshoot',
// 								'Baby Photoshoot',
// 								'Product Photoshoot',
// 								'Real Estate Photoshoot',
// 								'Restaurant Photoshoot',
// 								'Themed Photoshoot',
// 								'Conference/Expo',
// 								'Other'
// 							].map((cat) => (
// 								<Option key={cat} value={cat}>
// 									{cat}
// 								</Option>
// 							))}
// 						</Select>
// 					</Form.Item>
// 				</Col>

// 				<Col xs={24} lg={12}>
// 					<Form.Item
// 						name="eventDate"
// 						label="Event Date"
// 						rules={[{ required: true, message: 'Please select event date' }]}>
// 						<DatePicker className="full-width" />
// 					</Form.Item>
// 				</Col>
// 			</Row>

// <Row gutter={16}>
// 	<Col xs={24} sm={12}>
// 		<Row gutter={8} align="middle">
// 			<Col flex="auto">
// 				<Form.Item label="Enable Guest Upload" className="mb-0">
// 					<Switch onChange={setIsGuestUploadEnabled} />
// 				</Form.Item>
// 			</Col>
// 			{isGuestUploadEnabled && (
// 				<Col flex="none">
// 					<Form.Item
// 						name="guestMaxPhotos"
// 						label="Max Photos For Guest Upload"
// 						rules={[{ required: true, message: 'Please enter max photos' }]}
// 						className="mb-0">
// 						<InputNumber min={1} style={{ width: '100px' }} />
// 					</Form.Item>
// 				</Col>
// 			)}
// 		</Row>
// 	</Col>
// 	<Col xs={24} sm={12}>
// 		<Form.Item
// 			name="allowEnquiry"
// 			label="Allow Enquiry"
// 			valuePropName="checked"
// 			initialValue={true}
// 			className="mb-0">
// 			<Switch />
// 		</Form.Item>
// 	</Col>
// </Row>

// 			<h4>Hosts</h4>
// 			{hosts.map((host, index) => (
// 				<HostForm
// 					key={index}
// 					host={host}
// 					onUpdate={(field, value) => updateHost(index, field, value)}
// 					onRemove={() => removeHost(index)}
// 				/>
// 			))}

// 			{hosts.length < 5 && (
// 				<Form.Item>
// 					<Button
// 						type="dashed"
// 						onClick={addHost}
// 						icon={<PlusOutlined />}
// 						className="add-host-button">
// 						Add Host
// 					</Button>
// 				</Form.Item>
// 			)}

// 			<Form.Item className="form-actions">
// 				<Button type="primary" htmlType="submit">
// 					Create Event
// 				</Button>
// 			</Form.Item>
// 		</Form>
// 	);
// };

// export default EventForm;

import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useState } from 'react';
import HostForm from './HostForm';

const { Option } = Select;

const EventForm = ({
	userId,
	subscriptionId,
	selectedPackId,
	createEvent,
	eventCreated,
	creationType
}) => {
	const [form] = Form.useForm();
	const [isGuestUploadEnabled, setIsGuestUploadEnabled] = useState(false);

	const handleCreateEvent = async (values) => {
		const eventData = {
			name: values.eventName,
			EventType: creationType === 'subscription' ? 1 : 0,
			purchaseId: creationType === 'pack' ? selectedPackId : null,
			subscriptionId: creationType === 'subscription' ? subscriptionId : null,
			isGuestUploadEnabled,
			guestMaxPhotos: isGuestUploadEnabled ? values.guestMaxPhotos : null,
			category: values.category,
			eventDate: values.eventDate.toISOString().split('T')[0],
			allowEnquiry: values.allowEnquiry,
			hosts: values.hosts.filter((host) => host && host.name && host.email)
		};

		try {
			await createEvent(userId, eventData);
			eventCreated();
		} catch (error) {
			console.error('Failed to create event:', error);
		}
	};

	return (
		<Form
			form={form}
			onFinish={handleCreateEvent}
			layout="vertical"
			initialValues={{ hosts: [{}] }}>
			<Row gutter={16}>
				<Col xs={24} lg={24}>
					<Form.Item
						name="eventName"
						label="Event Name"
						rules={[{ required: true, message: 'Please enter event name' }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16}>
				<Col xs={24} lg={12}>
					<Form.Item
						name="category"
						label="Event Category"
						rules={[{ required: true, message: 'Please select event category' }]}>
						<Select>
							{[
								'Wedding',
								'Engagement',
								'Birthday',
								'Corporate',
								'Couple Shoot',
								'Family Photoshoot',
								'Maternity Photoshoot',
								'Fashion Model Photoshoot',
								'Baby Photoshoot',
								'Product Photoshoot',
								'Real Estate Photoshoot',
								'Restaurant Photoshoot',
								'Themed Photoshoot',
								'Conference/Expo',
								'Other'
							].map((cat) => (
								<Option key={cat} value={cat}>
									{cat}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>

				<Col xs={24} lg={12}>
					<Form.Item
						name="eventDate"
						label="Event Date"
						rules={[{ required: true, message: 'Please select event date' }]}>
						<DatePicker className="full-width" />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16}>
				<Col xs={24} sm={12}>
					<Row gutter={8} align="middle">
						<Col flex="auto">
							<Form.Item label="Enable Guest Upload" className="mb-0">
								<Switch onChange={setIsGuestUploadEnabled} />
							</Form.Item>
						</Col>
						{isGuestUploadEnabled && (
							<Col flex="none">
								<Form.Item
									name="guestMaxPhotos"
									label="Max Photos For Guest Upload"
									rules={[{ required: true, message: 'Please enter max photos' }]}
									className="mb-0">
									<InputNumber min={1} style={{ width: '100px' }} />
								</Form.Item>
							</Col>
						)}
					</Row>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="allowEnquiry"
						label="Allow Enquiry"
						valuePropName="checked"
						initialValue={true}
						className="mb-0">
						<Switch />
					</Form.Item>
				</Col>
			</Row>
			<h4>Hosts</h4>
			<Form.List name="hosts">
				{(fields, { add, remove }) => (
					<>
						{fields.map((field) => (
							<HostForm key={field.key} name={field.name} remove={remove} />
						))}
						{fields.length < 5 && (
							<Form.Item>
								<Button
									type="dashed"
									onClick={() => add()}
									icon={<PlusOutlined />}
									className="add-host-button">
									Add Host
								</Button>
							</Form.Item>
						)}
					</>
				)}
			</Form.List>

			<Form.Item className="form-actions">
				<Button type="primary" htmlType="submit">
					Create Event
				</Button>
			</Form.Item>
		</Form>
	);
};

export default EventForm;
