// File: src/services/cookieService.js

import { API_BASE_URL } from '../config';
import { fetchWrapper } from './fetchWrapper';

export const CookieService = {
	async setCookie(name, value, domain = undefined) {
		try {
			const response = await fetchWrapper.post(`${API_BASE_URL}v1/set-cookie`, {
				name,
				value,
				domain
			});
			return true;
		} catch (error) {
			console.error('Error setting cookie:', error);
			return false;
		}
	},

	async removeCookie(name, domain = undefined) {
		try {
			const response = await fetchWrapper.post(`${API_BASE_URL}v1/remove-cookie`, {
				name,
				domain
			});
			return true;
		} catch (error) {
			console.error('Error removing cookie:', error);
			return false;
		}
	}
};
