// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Button, Progress, Alert, Tooltip, Switch, Spin } from 'antd';
// import { bindAll } from 'lodash';
// import ls from 'local-storage';
// import ImageUploader from '../helpers/ImageUploader';
// import { ReactComponent as DragDropSVG } from '../svg/DragDropIcon.svg';
// import { CloudUpload } from 'lucide-react';

// const SUPPORTED_IMAGES = ['image/jpg', 'image/jpeg'];

// class UploadComp extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			isMobile: window.screen.width <= 576,
// 			isProcessing: false
// 		};
// 		bindAll(this, ['startUpload', 'handleFile', 'drop', 'allowDrop', 'handleWindowResize']);
// 		this.uploader = new ImageUploader();
// 	}

// 	componentDidMount() {
// 		window.addEventListener('resize', this.handleWindowResize);
// 	}

// 	componentWillUnmount() {
// 		window.removeEventListener('resize', this.handleWindowResize);
// 	}

// 	handleWindowResize = async () => {
// 		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
// 		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
// 	};

// 	UNSAFE_componentWillMount() {
// 		const { photosUploaded, uploadProgressUpdated, uploadFinished, uploadError } = this.props;
// 		var that = this;
// 		this.uploader.setOnProgressListener((status, pending, info) => {
// 			console.log('setOnProgressListener: ' + status);
// 			if (status === 'active') {
// 				uploadProgressUpdated(pending);
// 				return;
// 			}

// 			console.timeEnd('UPLOAD TOTAL TIME');
// 			photosUploaded(that.props.event, that.props.album);
// 			if (status === 'complete') {
// 				uploadFinished(info);
// 				setTimeout(() => {
// 					uploadFinished(undefined);
// 				}, 10000);
// 				return;
// 			}

// 			console.log(info);
// 			uploadError(info);
// 		});
// 	}

// 	drop = (e) => {
// 		e.preventDefault();
// 		this.setState({ isProcessing: true });
// 		console.log('starting image processing...');
// 		const { uploadError } = this.props;
// 		var fileArray = [];
// 		var cursor = 0;
// 		let files = e.dataTransfer.files;
// 		for (; cursor < files.length; ++cursor) {
// 			let file = files[cursor];
// 			if (SUPPORTED_IMAGES.indexOf(file.type) === -1) {
// 				uploadError('Only jpegs images are supported.');
// 				return;
// 			}
// 			fileArray.push(file);
// 		}
// 		this.startUpload(fileArray);
// 	};

// 	allowDrop = (e) => {
// 		e.preventDefault();
// 	};

// 	handleFile(e) {
// 		e.preventDefault();
// 		console.log('starting image processing...');
// 		this.setState({ isProcessing: true });
// 		var fileArray = [];
// 		var cursor = 0;
// 		for (; cursor < e.target.files.length; ++cursor) {
// 			fileArray.push(e.target.files[cursor]);
// 		}
// 		this.startUpload(fileArray);
// 		e.target.value = null;
// 	}

// 	startUpload = (fileArray) => {
// 		const { userId, event, album, uploadStarted, uploadError, eventPhotoCount } = this.props;
// 		if (fileArray.length > 2000) {
// 			uploadError('Maximum 2000 images can be uploaded at once');
// 			return;
// 		}
// 		uploadStarted(fileArray.length);
// 		this.uploader.setConfig({
// 			quality: 0.8,
// 			autoRotate: true,
// 			debug: false,
// 			userId: userId,
// 			event: event,
// 			album: album,
// 			eventPhotoCount: eventPhotoCount
// 		});
// 		this.uploader.isCancelled = false;
// 		this.uploader.uploadFileFromFileArray(fileArray).finally(() => {
// 			console.log('image processing finished...');
// 			this.setState({ isProcessing: false });
// 		});
// 	};

// 	isDragNDropSupported = () => {
// 		var div = document.createElement('div');
// 		return (
// 			('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
// 			'FormData' in window &&
// 			'FileReader' in window
// 		);
// 	};

// 	render() {
// 		const { successMessage, uploading, pending, total, error } = this.props;
// 		let header, footer;

// 		const isDragNDropSupported = this.isDragNDropSupported();

// 		let messageElement = null;

// 		if (uploading) {
// 			let progress = Math.trunc(((total - pending) / total) * 100);
// 			let progressLabel = 'Uploading ' + pending + ' photos';
// 			let progressLabelOnProgressBar = total - pending + '/' + total;

// 			if (pending === 0) {
// 				progressLabel = 'Completing Uploads..';
// 			}

// 			if (progress < 10) {
// 				progress = 10;
// 			}
// 			header = (
// 				<div>
// 					<p>{progressLabel}</p>
// 				</div>
// 			);
// 			footer = (
// 				<div className="upload-progress">
// 					<Tooltip className="upload-progress-bar" title={progressLabelOnProgressBar} sty>
// 						<Progress percent={progress} status="active" />
// 					</Tooltip>
// 					<Button
// 						className="upload-progress-cancel-button"
// 						onClick={(e) => {
// 							e.preventDefault();
// 							this.uploader.cancel();
// 						}}>
// 						Cancel
// 					</Button>
// 				</div>
// 			);
// 		} else {
// 			if (successMessage) {
// 				messageElement = (
// 					<Alert
// 						type="success"
// 						message={successMessage}
// 						style={{ marginTop: '10px' }}
// 						closable
// 						showIcon
// 					/>
// 				);
// 			}

// 			header = (
// 				<div>
// 					{isDragNDropSupported && !this.state.isProcessing ? (
// 						<div>
// 							<CloudUpload style={{ margin: '10px' }} size={36} />
// 							<h3 style={{ marginBottom: '15px' }}>
// 								<strong>Drag and drop your images here!</strong>
// 							</h3>
// 							<p></p>
// 							<h5>or</h5>
// 						</div>
// 					) : null}
// 				</div>
// 			);

// 			footer = this.state.isMobile ? (
// 				<div
// 					style={{ width: '100%', padding: '10px' }}
// 					role="button"
// 					onClick={(e) => {
// 						document.getElementById('fileUploadInputTypeFile').click();
// 						document.activeElement.blur();
// 					}}>
// 					<Spin spinning={this.state.isProcessing}>
// 						<input
// 							type="file"
// 							id="fileUploadInputTypeFile"
// 							onChange={this.handleFile}
// 							multiple="multiple"
// 							accept="image/.jpeg,image/.jpg"
// 							style={{ display: 'none' }}
// 						/>
// 					</Spin>

// 					{this.state.isProcessing ? (
// 						<Spin />
// 					) : (
// 						<div
// 							style={{
// 								display: 'flex',
// 								alignItems: 'center',
// 								backgroundColor: '#F3F3F3',
// 								padding: '10px',
// 								borderRadius: '6px',
// 								width: '100%'
// 							}}>
// 							<DragDropSVG style={{ marginRight: '10px' }} />
// 							<h3
// 								style={{
// 									margin: 0,
// 									fontSize: '22px',
// 									color: '#4A4A4A',
// 									textAlign: 'center',
// 									fontWeight: 600
// 								}}>
// 								Upload Photos
// 							</h3>
// 						</div>
// 					)}
// 				</div>
// 			) : (
// 				<div>
// 					<input
// 						type="file"
// 						id="fileUploadInputTypeFile"
// 						onChange={this.handleFile}
// 						multiple="multiple"
// 						accept="image/jpeg,image/jpg"
// 						style={{ display: 'none' }}
// 					/>
// 					{this.state.isProcessing ? (
// 						<Spin />
// 					) : (
// 						<>
// 							<Button
// 								className="btn-gray"
// 								style={{ margin: '15px', marginBottom: '20px', width: '200px' }}
// 								onClick={(e) => {
// 									e.preventDefault();
// 									document.getElementById('fileUploadInputTypeFile').click();
// 									document.activeElement.blur();
// 								}}>
// 								Browse to Upload
// 							</Button>
// 							<p style={{ marginBottom: '5px' }}>Maximum 2000 images can be uploaded at once</p>
// 						</>
// 					)}
// 				</div>
// 			);
// 		}

// 		if (error) {
// 			const { uploadError } = this.props;
// 			messageElement = (
// 				<Alert
// 					type="error"
// 					message={`Upload Error! ${error}`}
// 					style={{ marginTop: '10px' }}
// 					closable
// 					showIcon
// 					afterClose={() => uploadError(undefined)}
// 				/>
// 			);
// 		}

// 		if (isDragNDropSupported && uploading === false && !this.state.isProcessing) {
// 			if (!ls.get('addWatermarkDuringUpload')) {
// 				ls.get('addWatermarkDuringUpload', '1');
// 			}
// 			let addWatermark = ls.get('addWatermarkDuringUpload');

// 			return (
// 				<div className="uploadContainerWithDragDrop" onDrop={this.drop} onDragOver={this.allowDrop}>
// 					{messageElement}
// 					<div className="switchHolder upload-container-watermark-option">
// 						{this.state.isMobile ? (
// 							<h4
// 								style={{
// 									display: 'inline',
// 									color: '#111111DE',
// 									fontWeight: 600,
// 									fontSize: '20px'
// 								}}>
// 								Watermark
// 							</h4>
// 						) : (
// 							<h4 style={{ display: 'inline' }}>Add Watermark?</h4>
// 						)}
// 						<Switch
// 							checked={addWatermark === '0' ? false : true}
// 							onChange={(e) => {
// 								ls.set('addWatermarkDuringUpload', addWatermark === '0' ? '1' : '0');
// 								this.forceUpdate();
// 							}}
// 						/>
// 					</div>
// 					{!this.state.isMobile && header}
// 					{footer}
// 				</div>
// 			);
// 		}

// 		return (
// 			<div className="uploadContainerWithDragDrop">
// 				{messageElement}
// 				{header}
// 				{footer}
// 			</div>
// 		);
// 	}
// }

// UploadComp.propTypes = {
// 	uploading: PropTypes.bool.isRequired,
// 	pending: PropTypes.number.isRequired,
// 	total: PropTypes.number.isRequired,
// 	error: PropTypes.string,
// 	userId: PropTypes.string.isRequired,
// 	event: PropTypes.object.isRequired,
// 	eventPhotoCount: PropTypes.number,
// 	album: PropTypes.object.isRequired,
// 	uploadStarted: PropTypes.func.isRequired,
// 	uploadFinished: PropTypes.func.isRequired,
// 	uploadProgressUpdated: PropTypes.func.isRequired,
// 	uploadError: PropTypes.func.isRequired,
// 	successMessage: PropTypes.string,
// 	photosUploaded: PropTypes.func.isRequired
// };

// export default UploadComp;

import { Alert, Button, Progress, Space, Spin, Switch, Tooltip, Typography } from 'antd';
import ls from 'local-storage';
import { bindAll } from 'lodash';
import { CloudUpload } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImageUploader from '../helpers/ImageUploader';
import { ReactComponent as DragDropSVG } from '../svg/DragDropIcon.svg';

const { Text } = Typography;
const SUPPORTED_IMAGES = ['image/jpg', 'image/jpeg'];
const MAX_UPLOAD_COUNT = 2000;

class UploadComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576,
			isProcessing: false
		};
		bindAll(this, ['startUpload', 'handleFile', 'drop', 'allowDrop', 'handleWindowResize']);
		this.uploader = new ImageUploader();
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);

		// Log subscription and event type details
		const { event, subscription } = this.props;
		console.log('UploadComp - Initial Props:', {
			eventType: event?.eventType,
			isSubscriptionEvent: event?.eventType === 1,
			subscription: subscription
				? {
						id: subscription.id,
						status: subscription.status,
						uploadLimit: subscription.uploadLimit,
						uploadedPhotosCount: subscription.uploadedPhotosCount,
						maxPhotosLimit: subscription.maxPhotosLimit,
						currentPhotosCount: subscription.currentPhotosCount,
						expiresAt: subscription.expiresAt
				  }
				: 'No subscription'
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		const isMobile = window.innerWidth <= 576;
		if (isMobile !== this.state.isMobile) {
			this.setState({ isMobile });
		}
	};

	UNSAFE_componentWillMount() {
		const { photosUploaded, uploadProgressUpdated, uploadFinished, uploadError } = this.props;
		this.uploader.setOnProgressListener((status, pending, info) => {
			console.log('Upload status:', status);

			if (status === 'active') {
				uploadProgressUpdated(pending);
				return;
			}

			console.timeEnd('UPLOAD TOTAL TIME');
			photosUploaded(this.props.event, this.props.album);

			if (status === 'complete') {
				uploadFinished(info);
				setTimeout(() => uploadFinished(undefined), 10000);
				return;
			}

			console.log('Upload error:', info);
			uploadError(info);
		});
	}

	validateSubscriptionLimits = (fileCount) => {
		const { subscription, event } = this.props;

		// First check event type
		console.log('Validating upload limits:', {
			eventType: event?.eventType,
			isSubscriptionEvent: event?.eventType === 1
		});

		// Only check subscription for type 1 events
		if (!event?.eventType || event.eventType !== 1) {
			console.log('Regular event - skipping subscription validation');
			return { valid: true };
		}

		console.log('Subscription event - checking limits:', {
			subscription: subscription
				? {
						status: subscription.status,
						uploadLimit: subscription.uploadLimit,
						currentUploads: subscription.uploadedPhotosCount,
						maxPhotosLimit: subscription.maxPhotosLimit,
						currentPhotos: subscription.currentPhotosCount,
						expiresAt: subscription.expiresAt
				  }
				: 'No subscription'
		});

		if (!subscription) {
			console.log('No subscription found for subscription event');
			return { valid: false, message: 'No active subscription found' };
		}

		const now = new Date();
		const expiryDate = new Date(subscription.expiresAt);

		if (subscription.status !== 'active' || expiryDate <= now) {
			console.log('Subscription validation failed:', {
				status: subscription.status,
				expiryDate: subscription.expiresAt,
				isExpired: expiryDate <= now
			});
			return { valid: false, message: 'Subscription is not active or has expired' };
		}

		const remainingUploads = subscription.uploadLimit - subscription.uploadedPhotosCount;
		const remainingStorage = subscription.maxPhotosLimit - subscription.currentPhotosCount;

		console.log('Checking upload limits:', {
			requestedUpload: fileCount,
			remainingUploads,
			remainingStorage
		});

		if (fileCount > remainingUploads) {
			return {
				valid: false,
				message: `Upload limit exceeded. You can upload ${remainingUploads} more photos.`
			};
		}

		if (fileCount > remainingStorage) {
			return {
				valid: false,
				message: `Storage limit exceeded. You can store ${remainingStorage} more photos.`
			};
		}

		console.log('Subscription validation passed');
		return { valid: true };
	};

	drop = (e) => {
		e.preventDefault();
		this.setState({ isProcessing: true });

		const { uploadError } = this.props;
		const files = Array.from(e.dataTransfer.files);

		// Validate file types
		const invalidFiles = files.filter((file) => !SUPPORTED_IMAGES.includes(file.type));
		if (invalidFiles.length > 0) {
			uploadError('Only JPEG images are supported.');
			this.setState({ isProcessing: false });
			return;
		}

		this.startUpload(files);
	};

	allowDrop = (e) => {
		e.preventDefault();
	};

	handleFile = (e) => {
		e.preventDefault();
		this.setState({ isProcessing: true });
		const files = Array.from(e.target.files);
		this.startUpload(files);
		e.target.value = null;
	};

	startUpload = (fileArray) => {
		const { userId, event, album, uploadStarted, uploadError, eventPhotoCount, subscription } =
			this.props;

		console.log('Starting upload:', {
			eventType: event?.eventType,
			fileCount: fileArray.length,
			eventPhotoCount,
			isSubscriptionEvent: event?.eventType === 1
		});

		// Validate file count
		if (fileArray.length > MAX_UPLOAD_COUNT) {
			uploadError(`Maximum ${MAX_UPLOAD_COUNT} images can be uploaded at once`);
			this.setState({ isProcessing: false });
			return;
		}

		// Subscription validation only for type 1 events
		if (event?.eventType === 1) {
			console.log('Validating subscription limits for subscription event');
			const subscriptionValidation = this.validateSubscriptionLimits(fileArray.length);
			if (!subscriptionValidation.valid) {
				console.log('Subscription validation failed:', subscriptionValidation.message);
				uploadError(subscriptionValidation.message);
				this.setState({ isProcessing: false });
				return;
			}
		} else {
			console.log('Regular event - checking pack-based limits');
			// Your existing pack-based validation if needed
		}

		uploadStarted(fileArray.length);

		this.uploader.setConfig({
			quality: 0.8,
			autoRotate: true,
			debug: false,
			userId,
			event,
			album,
			eventPhotoCount,
			subscription: event?.eventType === 1 ? subscription : undefined // Only pass subscription for type 1 events
		});

		console.log('Starting file upload with config:', {
			eventId: event.id,
			eventType: event.eventType,
			albumId: album.id,
			fileCount: fileArray.length,
			hasSubscription: !!subscription
		});

		this.uploader.isCancelled = false;
		this.uploader.uploadFileFromFileArray(fileArray).finally(() => {
			console.log('Upload process finished');
			this.setState({ isProcessing: false });
		});
	};

	isDragNDropSupported = () => {
		var div = document.createElement('div');
		return (
			('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
			'FormData' in window &&
			'FileReader' in window
		);
	};

	// Modified renderLimits function for UploadComp
	renderLimits = () => {
		const { event, eventPhotoCount, subscription } = this.props;

		console.log('Rendering limits:', {
			eventType: event?.eventType,
			isSubscriptionEvent: event?.eventType === 1,
			hasSubscription: !!subscription,
			eventPhotoCount
		});

		// Check event type first
		if (event.eventType === 1) {
			if (subscription) {
				const remainingUploads = subscription.uploadLimit - subscription.uploadedPhotosCount;
				const remainingStorage = subscription.maxPhotosLimit - subscription.currentPhotosCount;

				console.log('Subscription limits:', {
					remainingUploads,
					remainingStorage,
					status: subscription.status
				});

				return (
					<Space direction="vertical" size="small" className="upload-limits">
						<Text>
							Upload limit: {remainingUploads} | Storage limit: {remainingStorage}
						</Text>
						{subscription.status !== 'active' && (
							<Alert type="warning" message="Subscription not active" showIcon />
						)}
					</Space>
				);
			}
			return <Alert type="warning" message="No active subscription found" showIcon />;
		}

		// For pack-based events
		if (eventPhotoCount !== undefined) {
			const maxPhotos = event.maxPhotos - (event.guestMaxPhotos || 0);
			const remaining = maxPhotos - eventPhotoCount;
			console.log('Pack-based limits:', {
				maxPhotos,
				eventPhotoCount,
				remaining
			});
			return <Text>Photos Remaining: {remaining}</Text>;
		}

		return null;
	};

	render() {
		const { successMessage, uploading, pending, total, error } = this.props;
		const isDragNDropSupported = this.isDragNDropSupported();
		let header, footer;
		let messageElement = null;

		// Handle upload progress
		if (uploading) {
			const progress = Math.max(10, Math.trunc(((total - pending) / total) * 100));
			const progressLabel = pending === 0 ? 'Completing Uploads..' : `Uploading ${pending} photos`;
			const progressBarLabel = `${total - pending}/${total}`;

			header = (
				<div>
					<p>{progressLabel}</p>
				</div>
			);
			footer = (
				<div className="upload-progress">
					<Tooltip className="upload-progress-bar" title={progressBarLabel}>
						<Progress percent={progress} status="active" />
					</Tooltip>
					<Button
						className="upload-progress-cancel-button"
						onClick={(e) => {
							e.preventDefault();
							this.uploader.cancel();
						}}>
						Cancel
					</Button>
				</div>
			);
		} else {
			// Show success message
			if (successMessage) {
				messageElement = (
					<Alert
						type="success"
						message={successMessage}
						style={{ marginTop: '10px' }}
						closable
						showIcon
					/>
				);
			}

			// Render upload interface
			header = (
				<div>
					{isDragNDropSupported && !this.state.isProcessing && (
						<div>
							<CloudUpload style={{ margin: '10px' }} size={36} />
							<h3 style={{ marginBottom: '15px' }}>
								<strong>Drag and drop your images here!</strong>
							</h3>
							{this.renderLimits()}
							<h5>or</h5>
						</div>
					)}
				</div>
			);

			footer = this.state.isMobile ? (
				<div
					className="mobile-upload-container"
					role="button"
					onClick={() => document.getElementById('fileUploadInputTypeFile').click()}>
					<Spin spinning={this.state.isProcessing}>
						<input
							type="file"
							id="fileUploadInputTypeFile"
							onChange={this.handleFile}
							multiple="multiple"
							accept="image/jpeg,image/jpg"
							style={{ display: 'none' }}
						/>
						{!this.state.isProcessing && (
							<div className="mobile-upload-button">
								<DragDropSVG style={{ marginRight: '10px' }} />
								<div className="mobile-upload-content">
									<h3>Upload Photos</h3>
								</div>
							</div>
						)}
					</Spin>
				</div>
			) : (
				<div>
					<input
						type="file"
						id="fileUploadInputTypeFile"
						onChange={this.handleFile}
						multiple="multiple"
						accept="image/jpeg,image/jpg"
						style={{ display: 'none' }}
					/>
					{this.state.isProcessing ? (
						<Spin />
					) : (
						<>
							<Button
								className="btn-gray"
								style={{ margin: '15px', marginBottom: '20px', width: '200px' }}
								onClick={() => document.getElementById('fileUploadInputTypeFile').click()}>
								Browse to Upload
							</Button>
							<p style={{ marginBottom: '5px' }}>
								Maximum {MAX_UPLOAD_COUNT} images can be uploaded at once
							</p>
						</>
					)}
				</div>
			);
		}

		// Handle errors
		if (error) {
			const { uploadError } = this.props;
			messageElement = (
				<Alert
					type="error"
					message={`Upload Error! ${error}`}
					style={{ marginTop: '10px' }}
					closable
					showIcon
					afterClose={() => uploadError(undefined)}
				/>
			);
		}

		// Render watermark option and main upload interface
		if (isDragNDropSupported && !uploading && !this.state.isProcessing) {
			const addWatermark = ls.get('addWatermarkDuringUpload') || '1';

			return (
				<div className="uploadContainerWithDragDrop" onDrop={this.drop} onDragOver={this.allowDrop}>
					{messageElement}
					<div className="switchHolder upload-container-watermark-option">
						<h4
							style={
								this.state.isMobile
									? {
											display: 'inline',
											color: '#111111DE',
											fontWeight: 600,
											fontSize: '20px'
									  }
									: { display: 'inline' }
							}>
							{this.state.isMobile ? 'Watermark' : 'Add Watermark?'}
						</h4>
						<Switch
							checked={addWatermark !== '0'}
							onChange={() => {
								ls.set('addWatermarkDuringUpload', addWatermark === '0' ? '1' : '0');
								this.forceUpdate();
							}}
						/>
					</div>
					{!this.state.isMobile && header}
					{footer}
				</div>
			);
		}

		return (
			<div className="uploadContainerWithDragDrop">
				{messageElement}
				{header}
				{footer}
			</div>
		);
	}
}

UploadComp.propTypes = {
	uploading: PropTypes.bool.isRequired,
	pending: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	error: PropTypes.string,
	userId: PropTypes.string.isRequired,
	event: PropTypes.shape({
		id: PropTypes.string.isRequired,
		eventType: PropTypes.number,
		maxPhotos: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		guestMaxPhotos: PropTypes.number
	}).isRequired,
	eventPhotoCount: PropTypes.number,
	album: PropTypes.object.isRequired,
	uploadStarted: PropTypes.func.isRequired,
	uploadFinished: PropTypes.func.isRequired,
	uploadProgressUpdated: PropTypes.func.isRequired,
	uploadError: PropTypes.func.isRequired,
	successMessage: PropTypes.string,
	photosUploaded: PropTypes.func.isRequired,
	subscription: PropTypes.shape({
		id: PropTypes.string,
		status: PropTypes.string,
		expiresAt: PropTypes.string,
		uploadLimit: PropTypes.number,
		maxPhotosLimit: PropTypes.number,
		uploadedPhotosCount: PropTypes.number,
		currentPhotosCount: PropTypes.number
	})
};

export default UploadComp;
