import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';

const HostForm = ({ name, remove }) => {
	return (
		<div className="host-form">
			<Row gutter={16} align="middle">
				<Col xs={24} sm={8}>
					<Form.Item
						name={[name, 'name']}
						label="Host Name"
						rules={[
							{ required: true, message: 'Please enter host name' },
							{ min: 3, message: 'Name must be at least 3 characters' }
						]}>
						<Input placeholder="Enter host name" />
					</Form.Item>
				</Col>
				<Col xs={24} sm={8}>
					<Form.Item
						name={[name, 'email']}
						label="Host Email"
						rules={[
							{ required: true, message: 'Please enter host email' },
							{ type: 'email', message: 'Please enter a valid email address' }
						]}>
						<Input placeholder="Enter host email" />
					</Form.Item>
				</Col>
				<Col xs={24} sm={8}>
					<Form.Item
						name={[name, 'mobileNumber']}
						label="Host Mobile Number"
						rules={[
							{ required: true, message: 'Please enter host mobile number' },
							{ pattern: /^[0-9]+$/, message: 'Please enter only numeric values' },
							{ len: 10, message: 'Mobile number must be between 10 digits' }
						]}>
						<Input placeholder="Enter host mobile number" />
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Button
						type="link"
						onClick={() => remove(name)}
						icon={<DeleteOutlined />}
						className="remove-host-button">
						Remove Host
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default HostForm;
