import { Tag } from 'antd';
import React from 'react';

const EventPhotoStats = ({ event }) => {
	const isSubscriptionEvent = event.eventType === 1;

	if (isSubscriptionEvent) {
		return (
			<div style={{ display: 'flex', alignItems: 'center', gap: 8, margin: '0 20px' }}>
				<Tag color="blue">Subscription</Tag>
				<span style={{ color: '#71717A', fontSize: '16px' }}>
					Photos: {event.currentPhotosCount || 0}
				</span>
			</div>
		);
	}

	return (
		<span style={{ color: '#71717A', fontSize: '16px', margin: '0 20px' }}>
			Photos: {event.currentPhotosCount || 0}/{event.maxPhotos - (event.guestMaxPhotos || 0)}
		</span>
	);
};

export default EventPhotoStats;
