import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { updatePhoneNumber } from '../actions/loginActions';
import { updateMyEventsMAction } from '../actions/pouchDBActions';
import DashboardComp from '../components/DashboardComp';
import Event from '../pouchDB/models/Event';
import Order from '../pouchDB/models/Order';
import Product, { PRODUCT_TYPE } from '../pouchDB/models/Product';
import Purchase from '../pouchDB/models/Purchase';
import Wallet from '../pouchDB/models/Wallet';

const mapStateToProps = (state) => {
	console.log('Dashboard mapStateToProps, state:', state);
	return {
		userId: state.auth.userId,
		email: state.auth.email,
		phone: state.auth.phone,
		whitelabelId: state.auth.whitelabelId,
		subscription: state.auth.subscription
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchPurchasedPacks: async (userId, whitelabelId, consistent) => {
		return await Purchase.fetchPurchases(userId, whitelabelId, consistent);
	},
	createEvent: async (userId, name, packId, purchaseId) => {
		return await Event.createEvent(userId, name, packId, purchaseId);
	},
	eventCreated: async () => {
		await dispatch(updateMyEventsMAction(1, true));
		dispatch(replace('/events'));
	},
	fetchBalance: async (userId) => {
		return await Wallet.fetchBalance(userId);
	},
	fetchWalletProduct: async (userId) => {
		return await Product.fetchProducts(userId, PRODUCT_TYPE.wallet);
	},
	placeRechargeOrder: async (userId, orderItems, rechargeAmount) => {
		return await Order.placeOrder(userId, orderItems, false, false, rechargeAmount);
	},
	confirmOrder: async (userId, orderId, razorPayPaymentId, signature, eventId) => {
		return await Order.confirmOrder(userId, orderId, razorPayPaymentId, signature, eventId);
	},
	redeem: async (userId, code) => {
		return await Wallet.redeem(userId, code);
	},
	updatePhoneNumber: (userId, phoneNumber) => dispatch(updatePhoneNumber(userId, phoneNumber))
});

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardComp);

export default Dashboard;
