import Swagger from 'swagger-client';
import { API_BASE_URL } from '../config';

let clientInternal = undefined;

const ensureHttps = (url) => {
	if (!url) return url;
	// Force HTTPS and remove any double slashes except after protocol
	return url.replace(/^(https?:\/\/)?(.*?)$/, 'https://$2').replace(/(https:\/\/)\/+/g, '$1');
};

const getBaseUrl = () => {
	const baseUrl = ensureHttps(API_BASE_URL);
	// Ensure the base URL ends with a single slash
	return baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
};

export const configureSwaggerClient = () => {
	const baseUrl = getBaseUrl();
	console.log('Configuring Swagger client with base URL:', baseUrl);

	return Swagger(`${baseUrl}swagger.json`, {
		schemes: ['https'],
		requestInterceptor: (req) => {
			// Force HTTPS for all requests
			req.url = ensureHttps(req.url);

			// Enable credentials for all requests
			req.credentials = 'include';

			// Add necessary headers
			req.headers = {
				...req.headers,
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-Requested-With': 'XMLHttpRequest'
			};

			// Log outgoing request details
			console.log('Outgoing request:', {
				url: req.url,
				method: req.method,
				headers: req.headers
			});

			return req;
		},
		responseInterceptor: (res) => {
			// Force HTTPS for response URL if present
			if (res.url) {
				res.url = ensureHttps(res.url);
			}
			return res;
		}
	})
		.then((client) => {
			if (client.errors && client.errors.length > 0) {
				console.error('Swagger client errors:', client.errors);
			}
			clientInternal = client;
			return client;
		})
		.catch((error) => {
			console.error('Failed to configure Swagger client:', error);
			throw error;
		});
};

export const swaggerClient = () => {
	return clientInternal;
};

export const loginWithFB = async (token) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.register({
			'auth-type': 0,
			token: token
		});
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};

export const loginWithCredentials = async (email, password, otp) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.login(
			{
				email: email,
				password: password,
				otp: otp
			},
			{
				requestInterceptor: (req) => {
					req.credentials = 'include';
					return req;
				}
			}
		);

		const response = JSON.parse(result.data);
		if (!response || !response.userId) {
			throw new Error('Invalid response from server');
		}
		return response;
	} catch (err) {
		console.error('Login error:', err);
		throw errorHandler(err);
	}
};

export const verifySession = async () => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.verify_session();
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};

export const sendOTP = async (email) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		await clientInternal.apis.login.send_otp({ email: email });
	} catch (err) {
		errorHandler(err);
	}
};

export const SignupResponse = Object.freeze({
	success: 1,
	needsOTP: 2,
	goToSetPassword: 3,
	error: 4,
	invalidOTP: 5
});

export const signup = async (name, email, password, otp) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		await clientInternal.apis.login.signup({
			name: name,
			password: password,
			email: email,
			otp: otp
		});
		return SignupResponse.success;
	} catch (err) {
		console.log(err);
		if (err.status === 409) {
			return SignupResponse.goToSetPassword;
		} else if (err.status === 425) {
			return SignupResponse.needsOTP;
		} else if (err.status === 401) {
			return SignupResponse.invalidOTP;
		} else {
			let response = JSON.parse(err.response.text);
			if (response && response.message) {
				return response.message;
			}
			return 'An unexpected error occurred. Please try again.';
		}
	}
};

export const errorHandler = (err) => {
	if (err.response && err.response.obj) {
		return {
			status: err.response.status,
			message: err.response.obj.message || 'An unexpected error occurred'
		};
	}
	return {
		status: err.status || 500,
		message: err.message || 'An unexpected error occurred'
	};
};

export const updateUserPhoneNumber = async (userId, phoneNumber) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.updatePhone({
			userId: userId,
			phone: phoneNumber
		});
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};
