// File: src/services/fetchWrapper.js

const defaultOptions = {
	credentials: 'include',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
};

export const fetchWrapper = {
	get: async (url) => {
		const response = await fetch(url, {
			...defaultOptions,
			method: 'GET'
		});
		return handleResponse(response);
	},

	post: async (url, body) => {
		const response = await fetch(url, {
			...defaultOptions,
			method: 'POST',
			body: JSON.stringify(body)
		});
		return handleResponse(response);
	}
};

const handleResponse = async (response) => {
	const text = await response.text();
	const data = text && JSON.parse(text);

	if (!response.ok) {
		const error = (data && data.message) || response.statusText;
		return Promise.reject(error);
	}

	return data;
};
