import { CopyOutlined } from '@ant-design/icons';
import { Button, Card, message, Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const EventsCodeCard = ({ code }) => {
	const copyToClipboard = () => {
		if (!code) return;

		navigator.clipboard
			.writeText(code)
			.then(() => {
				message.success('Code copied to clipboard!');
			})
			.catch(() => {
				message.error('Failed to copy code');
			});
	};

	return (
		<Card
			className="events-code-card"
			bodyStyle={{
				padding: '24px',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}>
			<Tag
				color="#6F4898"
				style={{
					position: 'absolute',
					top: '8px',
					right: '8px',
					borderRadius: '4px',
					padding: '1px 8px',
					fontSize: '11px',
					fontWeight: '500',
					textTransform: 'uppercase',
					letterSpacing: '0.5px',
					border: 'none'
				}}>
				Coming Soon
			</Tag>

			<div style={{ textAlign: 'center', marginBottom: '24px' }}>
				<h3
					style={{
						fontSize: '20px',
						margin: '0 0 24px',
						color: '#27272A',
						fontWeight: '700'
					}}>
					Follow All Your Events on App
				</h3>

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '12px',
						marginBottom: '16px'
					}}>
					<span
						style={{
							fontSize: '32px',
							fontWeight: 'bold',
							letterSpacing: '2px',
							color: '#27272A',
							fontFamily: 'monospace'
						}}>
						{code || '------'}
					</span>
					<Tooltip title="Copy code">
						<Button
							type="primary"
							icon={<CopyOutlined />}
							onClick={copyToClipboard}
							disabled={!code}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '40px',
								width: '40px',
								padding: '0'
							}}
						/>
					</Tooltip>
				</div>
			</div>

			<div
				style={{
					textAlign: 'center'
				}}>
				<p
					style={{
						color: '#71717A',
						fontSize: '14px',
						margin: '0 0 8px'
					}}>
					Use this code to follow all your events on app
				</p>
				<p
					style={{
						color: '#6F4898',
						fontSize: '12px',
						fontStyle: 'italic',
						margin: 0
					}}>
					This feature is under development and will be live soon on app
				</p>
			</div>
		</Card>
	);
};

EventsCodeCard.propTypes = {
	code: PropTypes.string
};

export default EventsCodeCard;
