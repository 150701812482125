import { Card, Tag } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const SubscriptionCard = ({ subscription }) => {
	const formatExpiryDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('en-GB', {
			day: 'numeric',
			month: 'short',
			year: 'numeric'
		});
	};

	const getDaysLeft = (dateString) => {
		const now = new Date();
		const expiry = new Date(dateString);
		const timeDiff = expiry.getTime() - now.getTime();
		const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
		return daysLeft > 0 ? daysLeft : 0;
	};

	return (
		<Card
			title="Subscription Details"
			style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
			bodyStyle={{
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				padding: '20px'
			}}>
			<div>
				<p
					style={{
						fontWeight: 'bold',
						textAlign: 'center',
						fontSize: '1.2em',
						marginBottom: '16px'
					}}>
					{subscription.name}
				</p>
				<div style={{ lineHeight: '1.4' }}>
					<p>
						<strong>Status:</strong>{' '}
						<Tag color={subscription.status === 'active' ? 'green' : 'red'}>
							{subscription.status}
						</Tag>
					</p>
					<p>
						<strong>Tier:</strong> {subscription.tier}
					</p>
					<p>
						<strong>Valid till:</strong> {formatExpiryDate(subscription.expiresAt)}{' '}
						<span style={{ color: 'gray' }}>({getDaysLeft(subscription.expiresAt)} days left)</span>
					</p>
					<p>
						<strong>Upload Limit:</strong> {subscription.maxPhotosLimit}
					</p>
				</div>
			</div>
			<p style={{ marginBottom: 0 }}>
				<strong>Features:</strong> Access to all features
			</p>
		</Card>
	);
};

SubscriptionCard.propTypes = {
	subscription: PropTypes.shape({
		name: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		tier: PropTypes.string.isRequired,
		expiresAt: PropTypes.string.isRequired,
		maxPhotosLimit: PropTypes.number.isRequired
	}).isRequired
};

export default SubscriptionCard;
